import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'TestRouting',
  data() {
    return {
      orderDataCode: '',
      routeRuleCode: '',
      testRoutingResult: '',
    };
  },
  computed: {
    ...mapState('routingRules', ['routingRuleCode']),
    isInvalidForm() {
      if (!this.routeRuleCode || !this.orderDataCode) {
        return true;
      }
      try {
        return !JSON.parse(this.orderDataCode);
      } catch (e) {
        return true;
      }
    },
  },
  beforeDestroy() {
    this.setRoutingRule('');
  },
  methods: {
    ...mapMutations('routingRules', ['setRoutingRule']),
    ...mapActions('routingRules', ['getRoutingRule']),
    async testRoutingRule() {
      this.$VBlackerTheme.openLoadingSpinner();
      try {
        const { data } = await this.$http.post('rules/test', {
          order: this.orderDataCode,
          rules: this.routeRuleCode,
        });
        this.testRoutingResult = JSON.stringify(data || '', null, '\t');
      } catch (e) {
        this.$VBlackerTheme.notification.error(e);
      } finally {
        this.$VBlackerTheme.closeLoadingSpinner();
      }
    },
    async fillLastRule() {
      this.$VBlackerTheme.openLoadingSpinner();
      try {
        await this.getRoutingRule();
        this.routeRuleCode = this.routingRuleCode;
      } catch (e) {
        this.$VBlackerTheme.notification.error(e);
      } finally {
        this.$VBlackerTheme.closeLoadingSpinner();
      }
    },
  },
};
